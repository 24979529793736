export default {
  address: '106台北市大安區浦城街6巷1號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3615.294087597815!2d121.52453151500615!3d25.024091883976983!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a98ff236b419%3A0x3261a47cfff7da47!2zMTA25Y-w5YyX5biC5aSn5a6J5Y2A5rWm5Z-O6KGXNuW3tzHomZ8!5e0!3m2!1szh-TW!2stw!4v1596611802368!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/oybEtLGgtMCv2MnLA',
  phone: '02-23690111',
  fbLink: 'https://www.facebook.com/拾秋-101023881706874/',
  fbMessage: 'https://m.me/101023881706874',
  caseName: '拾秋',
  indigatorLength: 10,

  houseInfos: [
    ['投資新建', '宜真建築開發股份有限公司'],
    ['建築設計', '三賦聯合建築師事務所'],
    ['公共空間', '創研空間設計'],
    ['建築代銷', '聯碩行銷股份有限公司'],
    ['建案位置', '台北市大安區浦城街6巷1號'],
    ['建照號碼', '108建字第0256號'],
  ],

  gtmCode: ['W6XBK63'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '預約賞屋',
    subTitle: ''
  }
}
